import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import ShareWidget from "./sharewidget"
import ShareCounter from "./sharecounter"
const _ = require("lodash")

export const query = graphql`
  fragment FullPostFragment on MarkdownRemark {
    html
    frontmatter {
      title
      category
      date
      author
      tags
      youtubeId
      image {
        fullImage: childImageSharp {
          fluid(maxWidth: 786) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageLegend
    }
    fields {
      slug
    }
  }
`

const FullPost = ({ post }) => (
  <article className="c-post c-big-post c-single-post">
    <header className="entry-header">
      <h6 className="entry-category">
        <Link to={`/${post.frontmatter.category}`}>
          {post.frontmatter.category}
        </Link>
      </h6>
      <h2 className="entry-title">
        <span>{post.frontmatter.title}</span>
      </h2>
    </header>

    {post.frontmatter.youtubeId ? (
      <div className="videoContainer">
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${post.frontmatter.youtubeId}`}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    ) : post.frontmatter.image ? (
      <div className="post-img-link-wrapper-featured">
        <Img
          fluid={post.frontmatter.image.fullImage.fluid}
          alt=""
          className="img-responsive"
        />
        {post.frontmatter.imageLegend && (
          <span className="titleImageLegend">
            {post.frontmatter.imageLegend}
          </span>
        )}
      </div>
    ) : (
      <div />
    )}

    <div
      className="entry-content"
      dangerouslySetInnerHTML={{ __html: post.html }}
    />

    <footer className="entry-footer clearfix">
      {post.frontmatter.tags && (
        <div className="entry-footer-top clearfix">
          <span className="tags-links">
            <span className="fa fa-tags"> </span>
            {post.frontmatter.tags.map(tag => (
              <Link key={tag} to={`/tags/${_.kebabCase(tag)}`} rel="tag">
                {tag}
              </Link>
            ))}
          </span>
        </div>
      )}
      <div className="entry-footer-bottom clearfix">
        <span className="posted-on footer-elems">
          <a href="#" rel="bookmark">
            <time
              className="entry-date published updated"
              dateTime={post.frontmatter.date}
            >
              {post.frontmatter.date}
            </time>
          </a>
        </span>
        <div className="share-links footer-elems">
          <i>SHARE</i>
          <ShareWidget slug={post.fields.slug} />
        </div>
        <span className="comments-link footer-elems">
          <ShareCounter slug={post.fields.slug} />
        </span>
      </div>
    </footer>
  </article>
)

export default FullPost
