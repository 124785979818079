import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import FullPost from "../components/fullpost"
import AuthorMiniature from "../components/authorminiature"
import SEO from "../components/seo"

const PostTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.post.frontmatter.title}
      description={
        data.post.frontmatter.imageLegend || data.post.frontmatter.excerpt
      }
      thumbnail={data.post.frontmatter.image.thumbnail.fixed.src}
    />
    <div className="content-area">
      <main className="site-main">
        <div className="bg-c-1 padd-xs-b60 padd-lg-b120">
          <div className="container padd-only-sm">
            <div className="row">
              <div className="col-xs-12 marg-lg-t60">
                <FullPost post={data.post} />
              </div>
            </div>
            <div className="row marg-lg-t40">
              <div className="col-xs-12">
                <AuthorMiniature name={data.post.frontmatter.author} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    {/* <!-- primary --> */}
  </Layout>
)

export default PostTemplate

export const query = graphql`
  query($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      ...FullPostFragment
      frontmatter {
        image {
          thumbnail: childImageSharp {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`
