import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const AuthorMiniature = ({ name }) => {
  // Cant use variables with useStaticQuery so we get all authors and filter on
  // the client.
  const data = useStaticQuery(graphql`
    query {
      allAuthorsJson {
        nodes {
          name
          id
          bio
          facebook
          instagram
          twitter
          youtube
          image {
            childImageSharp {
              fixed(width: 125, height: 125) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  const author = data.allAuthorsJson.nodes.find(author => author.name === name)
  if (author) {
    return (
      <section className="c-post-author clearfix">
        <Img
          className="author-image"
          alt={author.name}
          fixed={author.image.childImageSharp.fixed}
        />
        <div className="author-info">
          <h5 className="author-name">{author.name}</h5>
          <p className="author-desc">{author.bio}</p>
          <ul>
            {author.facebook && (
              <li>
                <a
                  href={author.facebook}
                  className="fa fa-facebook"
                  target="_blank"
                />
              </li>
            )}
            {author.twitter && (
              <li>
                <a
                  href={author.twitter}
                  className="fa fa-twitter"
                  target="_blank"
                />
              </li>
            )}

            {author.youtube && (
              <li>
                <a
                  href={author.youtube}
                  className="fa fa-youtube"
                  target="_blank"
                />
              </li>
            )}
            {author.instagram && (
              <li>
                <a
                  href={author.instagram}
                  className="fa fa-instagram"
                  target="_blank"
                />
              </li>
            )}
          </ul>
        </div>
      </section>
    )
  } else {
    return <div />
  }
}

export default AuthorMiniature
